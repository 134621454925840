import React, { useState, useEffect } from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
} from "react-share"
import Layout from "../retech/components/layout/Layout"
import {
  Dropdown,
  DropdownButton,
  Accordion,
  Card,
  Button,
  InputGroup,
  Modal
} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import { Link, graphql } from "gatsby"
import CarouselValue from "../retech/components/Value/CarouselValue"
import CarouselBenefits from "../retech/components/Benefits/CarouselBenefits"
import { Helmet } from "react-helmet"
import { SocialIcon } from "react-social-icons"
export default function CareersPage({ data }) {
  const [showEEO, setShowEEO] = useState(false)

  const handleClose = () => setShowEEO(false)
  const handleShow = () => setShowEEO(true)
  const careerhero = data.careerhero.nodes

  const category = data.category.nodes
  const BenefitsTitle = data.BenefitsTitle.nodes
  const socialmedia = data.socialmedia.nodes


  const category2 = data.category.opportunities
  const contactlocation = data.contactlocation.nodes
  const imagewidget = data.imagewidget.nodes
  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  const [CategorySelected, setCategorySelected] = useState("")
  useEffect(() => {
    if (data.category.nodes[0].opportunities[0].opportunityCategory.length > 0)
      setCategorySelected(
        data.category.nodes[0].opportunities[0].opportunityCategory
      )
  }, [data.category.nodes[0].opportunities[0].opportunityCategory])

  return (
    <Layout>
      <Helmet>
        <title>Retech | Careers</title>
        <meta
          name="description"
          content={data.metadata.nodes[0].CareerMetaDescription}
        />
        <meta name="keywords" content={data.metadata.nodes[0].Careerkeywords} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={data.metadata.nodes[0].CareerMetaTitle}
        />
        <meta
          property="og:description"
          content={data.metadata.nodes[0].CareerMetaDescription}
        />
        <meta property="og:image" content={urlimage + "/logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta
          property="twitter:title"
          content={data.metadata.nodes[0].CareerMetaTitle}
        />
        <meta
          property="twitter:description"
          content={data.metadata.nodes[0].CareerMetaDescription}
        />
        <meta property="twitter:image" content={urlimage + "/logo.svg"} />
      </Helmet>
      <Modal show={showEEO} onHide={handleClose} size="xl">
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body>
          <h3>The Retech Equal Employment Opportunity Statement</h3>
          <p className="product-par">
            Retech Systems LLC is an equal opportunity employer and makes
            employment decisions on the basis of merit. We want to have the best
            available persons in every job. Company policy prohibits unlawful
            discrimination based on race, color, creed, gender, religion,
            marital status, registered domestic partner status, age, national
            origin or ancestry, physical or mental disability, medical condition
            including genetic characteristics, sexual orientation, or any other
            consideration made unlawful by federal, state, or local laws. It
            also prohibits unlawful discrimination based on the perception that
            anyone has any of those characteristics, or is associated with a
            person who has or is perceived as having any of those
            characteristics. All such discrimination is unlawful.
          </p>
          <p className="product-par">
            The Company is committed to compliance with all applicable laws
            providing equal employment opportunities. This commitment applies to
            all persons involved in Company operations and prohibits unlawful
            discrimination by any employee of the Company, including supervisors
            and coworkers.
          </p>
          <p className="product-par">
            To comply with applicable laws ensuring equal employment
            opportunities to qualified individuals with a disability, the
            Company will make reasonable accommodations for the known physical
            or mental limitations of an otherwise qualified individual with a
            disability who is an applicant or an employee unless undue hardship
            would result.
          </p>
          <p className="product-par">
            Any applicant or employee who requires an accommodation in order to
            perform the essential functions of the job should contact a Company
            representative with day-to-day personnel responsibilities and
            request such an accommodation. The individual with the disability
            should specify what accommodation he or she needs to perform the
            job. The Company then will conduct an investigation to identify the
            barriers that interfere with the equal opportunity of the applicant
            or employee to perform his or her job. The Company will identify
            possible accommodations, if any, that will help eliminate the
            limitation. If the accommodation is reasonable and will not impose
            an undue hardship, the Company will make the accommodation.
          </p>
          <p className="product-par">
            If you believe you have been subjected to any form of unlawful
            discrimination, submit a written complaint to your supervisor or the
            individual with day-to-day personnel responsibilities. Your
            complaint should be specific and should include the names of the
            individuals involved and the names of any witnesses. If you need
            assistance with your complaint, or if you prefer to make a complaint
            in person, contact the Human Resources Manager. The Company will
            immediately undertake an effective, thorough, and objective
            investigation and attempt to resolve the situation.
          </p>
          <p className="product-par">
            If the Company determines that unlawful discrimination has occurred,
            effective remedial action will be taken commensurate with the
            severity of the offense. Appropriate action also will be taken to
            deter any future discrimination. The Company will not retaliate
            against you for filing a complaint and will not knowingly permit
            retaliation by management employees or your coworkers.
          </p>




        </Modal.Body>
      </Modal>
      <div className="container-fluid px-0 mb-5">
        {careerhero.map((node, index) => {
          return node.Career_Home
            ? node.Career_Home.map(q => (
              <>
                <div
                  key={index}
                  className="show_bg_desktop d-none d-sm-block"
                  style={{
                    backgroundImage:
                      "linear-gradient(80deg, #1F2025 4.11%, rgba(31, 32, 37, 0.3) 18.52%, rgba(31, 32, 37, 0) 36.03%, rgba(31, 32, 37, 0) 70.01%),url(" +
                      q.CareerImage +
                      ")",
                    height: "493px",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="container careers pt-5">
                    <div className="row pt-5 mt-5">
                      <div className="col-md-5 pt-5 mt-5">
                        <h1 className="font-wight-boder text-white ">{q.CareerTitle}</h1>
                        <p className="text-white font-weight-lighter product-par pr-0">
                          {q.Careerdescription}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 social"
                    style={{
                      top: "15rem",
                    }}
                  >
                    {socialmedia.map((node, index) => {
                      return (
                        <>
                          <div className="Demo__some-network mb-1">
                            <LinkedinShareButton
                              url={node.LinkedIn}
                              quote="{title}"
                              className="Demo__some-network__share-button"
                            >
                              <LinkedinIcon size={72} bgstyle="#000" />
                            </LinkedinShareButton>
                          </div>
                          <div className="Demo__some-network mb-1">
                            <FacebookShareButton
                              url={node.Facebook}
                              quote="{title}"
                              className="Demo__some-network__share-button"
                            >
                              <FacebookIcon size={72} bgstyle="#000" />
                            </FacebookShareButton>
                          </div>

                          <div className="Demo__some-network mb-1">
                            <PinterestShareButton
                              url={node.Youtube}
                              quote="{title}"
                              className="Demo__some-network__share-button"
                            >
                              <SocialIcon
                                url={node.Youtube}
                                network="youtube"
                                size={72}
                                bgstyle="#000"
                              />
                            </PinterestShareButton>
                          </div>
                        </>
                      )
                    })}


                  </div>
                </div>
              </>
            ))
            : null
        })}
        {careerhero.map((node, index) => {
          return node.Career_Home
            ? node.Career_Home.map(q => (
              <>
                <div
                  key={index}
                  className="show_bg_desktop d-block d-sm-none"
                  style={{
                    backgroundImage:
                      "linear-gradient(80deg, #1F2025 4.11%, rgba(31, 32, 37, 0.3) 18.52%, rgba(31, 32, 37, 0) 36.03%, rgba(31, 32, 37, 0) 70.01%),url(" +
                      q.CareerImage +
                      ")",
                    backgroundSize: "cover",
                    height: "300px",
                  }}
                >
                  <div className="container careers pt-5">
                    <div className="row">
                      <div className="col-md-5">
                        <h1 className="font-wight-boder text-white ">{q.CareerTitle}</h1>
                        <p className="text-white product-par pr-5 mr-5">
                          {q.Careerdescription}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
            : null
        })}
      </div>
      <div className="container faq">
        <div className="col-md-12 py-5">
          <div className="row py-4">
            <div className="col-md-4 d-block d-sm-none mb-5 dropdown-filter">
              <DropdownButton
                id="dropdown-filter"
                title="Category"
                variant="secondary"
              >
                {category.map((node, index) => {
                  return node.opportunities
                    ? DistinctRecords(
                      node.opportunities,
                      "opportunityCategory"
                    ).map((q, index2) => (
                      <>
                        <Dropdown.Item
                          value={q.opportunityCategory}
                          onClick={() => {
                            setCategorySelected(q.opportunityCategory)
                          }}
                        >
                          {q.opportunityCategory}
                        </Dropdown.Item>
                      </>
                    ))
                    : null
                })}
              </DropdownButton>
            </div>
            <div className="col-md-4 d-none d-sm-block">
              <h6 className="font-weight-bold">Category</h6>

              {category.map((node, index) => {
                return node.opportunities
                  ? DistinctRecords(
                    node.opportunities,
                    "opportunityCategory"
                  ).map((q, index2) => (
                    <>
                      <small
                        onClick={() => {
                          setCategorySelected(q.opportunityCategory)
                        }}
                      >
                        <a
                          className="text-dark"
                          value={q.opportunityCategory}
                        >
                          {q.opportunityCategory}
                        </a>
                      </small>
                      <br></br>
                    </>
                  ))
                  : null
              })}
            </div>
            <div className="col-md-8">
              <h4 className="font-weight-bolder mb-3">Career Opportunities</h4>

              {category.map((node, index) => {
                return node.opportunities
                  ? node.opportunities
                    .filter(x => x.opportunityCategory === CategorySelected)
                    .map((q, index2) => (
                      <>
                        <Accordion
                          defaultActiveKey={index + "0"}
                          className="border-top py-3"
                        >
                          <Accordion.Toggle
                            eventKey={index + "" + index2}
                            as={Button}
                            variant="link"
                            className="shadow-none text-left w-100"
                          >
                            {q.opportunityTitle}
                            <FontAwesomeIcon
                              className="ml-3 float-right fa-lg"
                              icon={faChevronCircleRight}
                            />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={index + "" + index2}>
                            <Card.Body >
                              {" "}
                              <p className="product-par">
                                {q.opportunityDescription}{" "}
                              </p>
                              <p className="parallelogram mb-4 mr-3 active-filter">
                                <a
                                  target="_blank"
                                  className="text-dark "
                                  href={q.opportunityCTALink}
                                >
                                  Full Job Description
                                </a>

                              </p>
                              <p className="parallelogram mb-4 mr-3 active-filter">
                                <a

                                  className="text-dark "
                                  target="_blank"
                                  href={"https://recruiting.paylocity.com/recruiting/jobs/All/cdfe4b92-0529-47e8-83ca-da9613b9dcfa/Retech-Systems-LLC"}
                                >
                                  Apply
                                </a>

                              </p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      </>
                    ))
                  : null
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="col-md-12">
            <div className="row">
              {BenefitsTitle.map((node, index) => {
                return (
                  <>
                    <div className="col-md-12 pb-5 mt-2 text-center" key={index}>
                      <h3 className="font-weight-bold">{node.BenefitsTitleTitle}</h3>
                      <p class="text-muted" style={{ paddingBottom: "4rem" }}>{node.BenefitsTitleDescription}</p>
                    </div>
                  </>
                )
              })}



            </div>
            <div className="row careers-carousel">
              <CarouselBenefits />
            </div>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="col-md-12">
          <div className="row py-5">
            <div className="col-md-10 mx-auto text-center mb-3">
              <h3 className="mb-4 font-weight-bolder">Retech Locations</h3>
            </div>
          </div>
        </div>
        <div className="col-md-12 d-none d-sm-block">
          <div className="row pt-4 pb-5 mb-5 locationcareer">
            {contactlocation.map((node, index) => {
              return (
                <>
                  {node?.location?.length > 0 &&
                    node.location.map(node2 => (
                      <div className="col-xl-3 col-lg-3 text-center" key={index}>
                        <img src={node2.locationImage} alt="buffalo" />
                      </div>
                    ))}
                </>
              )
            })}

            {contactlocation.map((node, index) => {
              return (
                <>
                  {node?.location?.length > 0 &&
                    node.location.map((node2, index) => (
                      <div
                        className="col-xl-3 col-lg-3 text-center border-right"
                        key={index}
                      >
                        <h4 className="mb-2 font-weight-lighter">
                          {node2.locationTitle}
                        </h4>
                        <p className="text-muted mb-1">
                          <small> {node2.locationAddres1}</small>
                        </p>
                        <p className="text-muted mb-1">
                          <small>{node2.locationAddres2}</small>
                        </p>
                        <p className="text-muted mb-1">
                          <small>
                            Phone:{" "}
                            <a href={"tel:" + node2.locationPhone}>
                              {node2.locationPhone}
                            </a>
                          </small>
                        </p>
                        <p className="text-muted mb-1">
                          <small>
                            {" "}
                            Email:{" "}
                            <a href={"mailto:" + node2.locationEmail}>
                              {node2.locationEmail}
                            </a>
                          </small>
                        </p>
                        <div className="d-flex justify-content-center mt-3">
                          <a href={node2.locationCTALink} target="_blank">
                            <h6 className="font-weight-bolder mr-2 mb-0 align-self-center Direction-text">
                              {node2.locationCTAText}
                            </h6>
                          </a>
                          <img src="/img/arrow.svg" alt="arrow" />
                        </div>
                      </div>
                    ))}
                </>
              )
            })}
          </div>
        </div>
        <div className="col-md-12 d-block d-sm-none">
          <div className="row pt-4 pb-5 mb-5">
            {contactlocation.map((node, index) => {
              return (
                <>
                  {node?.location?.length > 0 &&
                    node.location.map(node2 => (
                      <div
                        className="col-md-4 text-center border-bottom mb-4 pb-4"
                        key={index}
                      >
                        <img
                          src={node2.locationImage}
                          alt={node2.locationTitle}
                        />
                        <h4 className="mb-2 font-weight-lighter">
                          {node2.locationTitle}
                        </h4>
                        <p className="text-muted mb-1">
                          <small>{node2.locationAddres1}</small>
                        </p>
                        <p className="text-muted mb-1">
                          <small>{node2.locationAddres2}</small>
                        </p>
                        <p className="text-muted mb-1">
                          <small>Phone: {node2.locationPhone}</small>
                        </p>
                        <p className="text-muted mb-1">
                          <small> Email:{node2.locationEmail}</small>
                        </p>
                        <div className="d-flex justify-content-center mt-3">
                          <a href={node2.locationCTALink} target="_blank">
                            <h6 className="font-weight-bolder mr-2 mb-0 align-self-center Direction-text">
                              {node2.locationCTAText}
                            </h6>
                          </a>
                          <img src="/img/arrow.svg" alt="arrow" />
                        </div>
                      </div>
                    ))}
                </>
              )
            })}
          </div>
        </div>
      </div>
      {imagewidget.map((node, index) => {
        return (
          <>
            <div
              className="show_bg_desktop container-fluid"
              style={{
                backgroundImage:
                  " linear-gradient(349.66deg, #002A5C 7.72%, rgba(0, 0, 0, 0) 92.28%),url(" +
                  node.ImageWidgetImage +
                  ")",
                height: "300px",
                backgroundSize: "cover",
              }}
              key={index}
            >
              <div className="container careers pt-5">
                <div className="row pt-5">
                  <div className="col-md-12  text-center">
                    <h4 className="font-wight-boder ">
                      {node.ImageWidgetTitle}{" "}
                    </h4>
                    <InputGroup className="mt-3 justify-content-center">
                      <InputGroup.Append>
                        <Button
                          variant="outline-secondary"
                          id="Subscribe"
                          className="text-white"
                          onClick={handleShow}
                        >
                          {node.valuesWidgetCTAText}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      })}
    </Layout>
  )
}
function DistinctRecords(MYJSON, prop) {
  return MYJSON.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}
export const query = graphql`
  query {
    careerhero: allCareersJson(
      filter: { Career_Home: { elemMatch: { CareerTitle: { ne: null } } } }
    ) {
      nodes {
        Career_Home {
          CareerImage
          CareerTitle
          Careerdescription
        }
      }
    }
    socialmedia: allHomeJson(filter: {Facebook: {ne: null}}) {
      nodes {
        Youtube
        Facebook
        LinkedIn
      }
    }
    BenefitsTitle: allCareersJson(filter: {BenefitsTitleTitle: {ne: null}}) {
      nodes {
        BenefitsTitleDescription
        BenefitsTitleTitle
      }
    }
    category: allCareersJson(
      filter: {
        opportunities: { elemMatch: { opportunityTitle: { ne: null } } }
      }
    ) {
      nodes {
        opportunities {
          opportunityCTALink
          opportunityCategory
          opportunityDescription
          opportunityTitle
        }
      }
    }

   
    contactlocation: allContactJson(
      filter: { location: { elemMatch: { locationTitle: { ne: null } } } }
    ) {
      nodes {
        location {
          locationAddres1
          locationAddres2
          locationCTALink
          locationCTAText
          locationEmail
          locationImage
          locationPhone
          locationTitle
        }
      }
    }
    imagewidget: allCareersJson(filter: { ImageWidgetImage: { ne: null } }) {
      nodes {
        ImageWidgetImage
        ImageWidgetTitle
        valuesWidgetCTAText
        valuesWidgetCTALink
      }
    }

    
    metadata: allMetadataJson(
      filter: { ProductMetaTitle: {}, CareerMetaTitle: { ne: null } }
    ) {
      nodes {
        CareerMetaDescription
        CareerMetaTitle
        Careerkeywords
      }
    }
  }
`
